<template>
  <div>
    <div class="loading_block_outer" v-if="loading">
    <Loading3 :msg='loadingMessage'/>
    <div class="snippet" data-title=".dot-spin">
      <div class="stage">
        <div class="dot-spin"></div>
      </div>
    </div>
  </div>
    <div v-else class="container">
      <div class="propno">
          <span v-if="this.applicationNo">Insurer Application Number: {{this.applicationNo}}</span><br>
      <span v-if="this.uiQuoteId">Reference Number: {{this.uiQuoteId}}</span>
        </div>
        <div class="failed-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_failed.png" alt="" />
          </figure>
          <br>
          <figcaption class="mb-4">

            <span id="errorText">Application Error</span><br><br/>
            <!-- <span id="errorText">We are facing with following error.</span><br> -->
            
            <div v-if="this.msgError">
            <span id="errorMsg">{{this.msgError}}.</span>
            </div>
            <div v-if="this.msg.length=='1'">
            <span  id="errorMsg">{{this.msg[0]}}.</span>
            </div>
            <div v-else>
             <span v-if="!flag" id="errorMsg">{{this.msg[0]}}....<button id="moreBtn" v-on:click="moreError">more</button></span>
            <span v-if="flag" id="errorMsg">{{this.msg[0]}}....<button id="moreBtn" v-on:click="moreError">less</button></span>
            </div>
            <!-- <div v-if="flag"> -->
            <div v-if="flag" v-for="(error,index) in this.msg" :key="error">
            <span v-if="index!='0'" id="errorMsg">{{error}}.</span>
            </div>
            <div v-if="this.reqId">
            <span id="reqId"><b>Request Id: </b>{{this.reqId}}.</span>
            </div>
            <!-- <div>
            <span  id="errorMsg">{{this.msg[0]}}.</span>
            </div> -->
            
            <!-- <router-link to="/policydetails">
             <button id="btn">Go back</button>
            </router-link> -->
            <button v-if="this.backUrl=='logout'" v-on:click="logout" id="btn">Relogin</button>
            <button v-else v-on:click="goBack" id="btn">Go back</button><br>
            <span v-if="this.backUrl=='logout'" id="warning_msg">*Please do not click browser back button.</span>
          </figcaption>
        </div>
    </div>
  </div>
</template>


<script>
import { getDistributer } from '../../mixins/common';
import Loading3 from "@/components/loading3.vue";
export default {
  name: "networkalert",
  props: {  },
  components: {
    Loading3
  },
  data() {
    return {
        msg:[],
        msgError:"",
        backUrl:"",
        flag:false,
        reqId:"",
        applicationNo:"",
        selectedPlanData:"",
        rmData:"",
        loading:false,
        loadingMessage:""
    };
  },
  
  mounted() {
      //  sessionStorage.setItem("quote_request_checkout_flag", JSON.stringify(1));

    let m=this.$route.query.msg
    let back=this.$route.query.backUrl
    let e=this.$route.query.msgError
    this.msgError=e
    let r=this.$route.query.reqId
    this.reqId=r
    console.log("error",this.msgError)
    this.backUrl=back
    if(typeof(m)=="string")
    this.msg.push(m)
    else
    this.msg=m
    if(sessionStorage.getItem("rm_data"))
    {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
    }
    if(sessionStorage.getItem('uiQuoteId')){
      this.uiQuoteId = JSON.parse(sessionStorage.getItem('uiQuoteId'));
    }
    this.selectedPlanData = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    if(this.selectedPlanData!=null && this.selectedPlanData["insuranceAndProducts"]["insuranceCompanyCode"] == "HDFC")
       {
       this.applicationNo=this.selectedPlanData["trackInfo"]["varFields"][0]["fieldValue"]
       }
   
   
    
    // this.paymentResponse();
    //this.getPayment()
  
  },
  methods: {
    logout()
    {
            this.loadingMessage = "Redirecting to login page"
            this.loading=true
            sessionStorage.clear();
            localStorage.clear()
            if(this.rmData['RoleId']=='1')
            {
              window.location.href = getDistributer()['loginUrlB2C'];
              // window.history.replaceState("", "", getDistributer()['loginUrlB2C']);
              // window.location.reload();
            }
            else
            {
              window.location.href = getDistributer()['loginUrlB2B'];
              // window.history.replaceState("", "", getDistributer()['loginUrlB2B']);
              // window.location.reload();
            }        
    },
    moreError()
    {
       this.flag=!this.flag
    },
    goBack()
    {
        if(this.backUrl=="policy-quote")
        this.$router.push({path: '/'})
        else if(this.backUrl=="policy-details")
        this.$router.push({path: '/termplanqte'})
        else if(this.backUrl=="proposal-page")
        this.$router.push({path: '/proposal'})
        else if(this.backUrl=="termplanqte")
        this.$router.push({path: '/policy-quote'})
        else if(this.backUrl=="proposal-details-check")
        this.$router.push({path: '/proposal'})
        else if(this.backUrl=="dashboard")
        this.$router.push({path: '/dashboard'})
              
    }
  },
};
</script>

<style scoped lang="scss">

#errorMsgHeading{
  font-size: 17px;
}
#errorText{
    font-size: 20px;
}
#errorMsg{
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    font-weight: bolder;
}
#reqId{
  font-size: 15px;
    font-weight: 400;
    text-align: left;
}
#moreBtn{
    color: blue;
    cursor: pointer;
    border: none;
    background-color: white;
}
#btn{
    width:70px;
    height:30px;

    font-size: 15px;
    border:solid 1px rgb(245, 94, 94);
    border-radius: 4px;
    background-color: rgb(245, 94, 94)
}
.propno{
    text-align: right;
    margin-right: 50px;
    margin-top: 10px;
}
#warning_msg
{
  color: red;
}
@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
</style>