<template>
    <div>
         <div v-if="this.rmData['RoleId']=='1'">
        <innerheader />
      </div>
      <div v-else>
      <innerheaderDIY/>
      </div>
        <payment />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheaderDIY from "@/components/innerheader/innerHeaderDIY.vue";
import innerheader from "@/components/innerheader/innerheader.vue";
import payment from "@/components/payment/payment.vue";

export default {
  name: 'paymnt',
  components: {
     innerheader,
     innerheaderDIY,
     payment
  },
  data()
  {
    return{
      rmData:"",
    }
  },
   mounted()
  {
     if(sessionStorage.getItem("rm_data"))
     {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
     }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/paymentsuccess.scss';
</style>