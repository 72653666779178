<template>
  <div
      class="modal fade"
      id="editsecondprofile"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editsecondprofileLabel"
      aria-hidden="true"
    > 
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editsecondprofileLabel">
              Edit Second Life Profile
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="">
              <div class="d-flex justify-content-center align-items-center">
                <div class="mr-15 active w-50">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="gender-rad"
                    id="male"
                    v-model="user2Data.gender"
                    v-bind:value="'Male'"
                  />
                  <label
                    class="for-checkbox-gender d-flex align-items-center"
                    for="male"
                  >
                    <i class=""
                      ><img src="~@/assets/images/male.svg" alt=""
                    /></i>
                    <i class="inactive"
                      ><img src="~@/assets/images/inactive-male.png" alt=""
                    /></i>
                    Male
                  </label>
                </div>
                <div class="w-50">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="gender-rad"
                    id="female"
                    v-model="user2Data.gender"
                    v-bind:value="'Female'"
                  />
                  <label
                    class="for-checkbox-gender d-flex align-items-center"
                    for="female"
                  >
                    <i class=""
                      ><img src="~@/assets/images/female1.png" alt=""
                    /></i>
                    <i class="inactive"
                      ><img src="~@/assets/images/female.png" alt=""
                    /></i>
                    Female
                  </label>
                </div>
                <div>
                  <p class="error-text" v-if="error2.gender">
                    {{ error2.gender }}
                  </p>
                </div>
              </div>
              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="fullname"
                  name="fullname"
                  pattern="[A-Za-z]"
                  v-model.trim="user2Data.fullname"
                  @keypress="lettersSpaceOnly($event)"
                />
                <label for="username" class="form-item-label">Full name</label>
                <p class="error-text" v-if="error2.fullname">
                  {{ error2.fullname }}
                </p>
              </div>
              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="date"
                  id="dob2"
                  name="dob2"
                  v-on:input="calAge2"
                  v-model="user2Data.dob"
                />
                <label for="dob2" class="form-item-label">Date of Birth</label>
                <p class="error-text" v-if="error2.dob">{{ error2.dob }}</p>
              </div>
              <div class="form-control1 label-clicked">
                <select
                  name="employee2"
                  id="employee2"
                  v-model="user2Data.employmenttype"
                >
                  <option value="Salaried">Salaried</option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="Self-employed">Self-Employed</option>
                </select>
                <label for="employee2" class="form-item-label"
                  >Employment Type</label
                >
                <p class="error-text" v-if="error2.employmenttype">
                  {{ error2.employmenttype }}
                </p>
              </div>
              <div class="radio-question">
                <h4>Do you Smoke or Chew Tobacco?</h4>
                <div class="d-flex">
                  <div class="mr-15 w-50">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="smoke1"
                      id="yes"
                      v-model="user2Data.smoke"
                      v-bind:value="'Yes'"
                    />
                    <label class="for-checkbox-gender" for="yes">
                      <i class="">
                        <img src="~@/assets/images/smoking.svg" alt="" />
                      </i>
                      <!-- <i class="inactive">
                        <img
                          src="~@/assets/images/smoking-inactive.png"
                          alt=""
                        />
                      </i> -->
                      Yes
                    </label>
                  </div>
                  <div class="w-50">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="smoke1"
                      id="no"
                      v-model="user2Data.smoke"
                      v-bind:value="'No'"
                    />
                    <label class="for-checkbox-gender" for="no">
                      <i>
                        <img src="~@/assets/images/non-smoking.svg" alt="" />
                      </i>
                      <!-- <i class="inactive">
                         <img src="~@/assets/images/non-smoking-inactive.png" alt=""/>
                      </i> -->
                      No
                    </label>
                  </div>
                </div>
                <p class="error-text" v-if="error2.smoke">
                  {{ error2.smoke }}
                </p>
              </div>
            </form>
          </div>
          <div class="text-success text-center" v-if="successMsg2">
            {{ successMsg2 }}
          </div>
          <div class="modal-footer">
            <button type="button" v-on:click="updateProfile2"  class="save-btn">Save Profile</button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="editprofile"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editprofileLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editprofileLabel">Edit Profile</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="step-1">
              <div class="d-flex radio-btn-wrap">
                <div class="mr-15">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="tool-1"
                    v-model="userData.gender"
                    v-bind:value="'Male'"
                  />
                  <label class="for-checkbox-tools" for="tool-1">
                    <i><img src="~@/assets/images/male.svg" alt="" /></i>
                    <i class="inactive"
                      ><img src="~@/assets/images/male.svg" alt=""
                    /></i>
                    Male
                  </label>
                </div>
                <!--apply active class here for active state img-->
                <div class="mr-15">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="tool-2"
                    v-model="userData.gender"
                    v-bind:value="'Female'"
                  />
                  <label class="for-checkbox-tools" for="tool-2">
                    <i><img src="~@/assets/images/female.svg" alt="" /></i>
                    <i class="inactive"
                      ><img src="~@/assets/images/female-inactive.svg" alt=""
                    /></i>
                    Female
                  </label>
                </div>
                <div class="">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="tool-3"
                    v-model="userData.gender"
                    v-bind:value="'Others'"
                  />
                  <label class="for-checkbox-tools" for="tool-3">
                    <i><img src="~@/assets/images/transgender.png" alt="" /></i>
                    <i class="inactive"
                      ><img
                        src="~@/assets/images/transgender-inactive.svg"
                        alt=""
                    /></i>
                    Others
                  </label>
                </div>
                <p class="error-text" v-if="error.gender">
                    {{ error.gender }}
                </p>
              </div>
              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="fullname"
                  name="fullname"
                  pattern="[A-Za-z]"
                  v-model.trim="userData.fullname"
                  @keypress="lettersSpaceOnly($event)"
                />
                <label for="username" class="form-item-label">Full name</label>
                <p class="error-text" v-if="error.fullname">
                  {{ error.fullname }}
                </p>
              </div>
              <div class="d-flex form-control1-wrap">
                <div class="form-control1 label-clicked">
                  <input
                    class="form-text"
                    type="date"
                    id="dob"
                    name="dob"
                    v-on:input="calAge"
                    v-model="userData.dob"
                  />
                  <label for="dob" class="form-item-label">Date of Birth</label>
                  <span v-if="userData.age" class="blue-bg-span"
                    >{{ userData.age }} Years</span
                  >
                  <p class="error-text" v-if="error.dob">{{ error.dob }}</p>
                </div>
                <div class="form-control1 label-clicked">
                  <select name="country-code" id="country-code">
                    <option value="+91">+91</option>
                    <option value="+57">+57</option>
                  </select>
                  <span></span>
                  <input
                    class="form-text"
                    type="text"
                    id="mob-no"
                    name="mob-no"
                    maxlength="10"
                    v-model.trim="userData.mobile"
                    @keypress="isNumber($event)"
                  />
                  <label for="mob-no" class="form-item-label"
                    >Mobile Number</label
                  >
                  <p class="error-text" v-if="error.mobile">
                    {{ error.mobile }}
                  </p>
                </div>
              </div>
              <div class="d-flex form-control1-wrap">
                <div class="form-control1 zip-code-input label-clicked">
                  <input
                    class="form-text"
                    type="text"
                    id="zip-code"
                    name="zip-code"
                    pattern="\d*"
                    maxlength="6"
                    v-model.trim="userData.zipcode"
                    @keypress="isNumber($event)"
                  />
                  <label for="zip-code" class="form-item-label">Pin Code</label>
                  <!-- <span class="blue-bg-span">Mumbai</span> -->
                  <p class="error-text" v-if="error.zipcode">
                    {{ error.zipcode }}
                  </p>
                </div>
                <div class="form-control1 zip-code-input label-clicked">
                  <input
                    class="form-text"
                    type="text"
                    id="income"
                    name="income"
                    v-on:input="getInLakhs"
                    v-model.trim="userData.annualincome"
                    @keypress="isNumber($event)"
                  />
                  <label for="zip-code" class="form-item-label"
                    >Annual Income</label
                  >
                  <p class="error-text" v-if="error.annualincome">
                    {{ error.annualincome }}
                  </p>
                  <span class="blue-bg-span" v-if="userData.inlakhs"
                    >&#8377; {{ userData.inlakhs }}</span
                  >
                </div>
              </div>
              <div class="form-control1 label-clicked">
                <select
                  name="employee"
                  id="employee"
                  v-model="userData.employmenttype"
                >
                  <option value="Salaried">Salaried</option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="self-employed">Self-Employed</option>
                </select>
                <label for="employee" class="form-item-label"
                  >Employment Type</label
                >
                <p class="error-text" v-if="error.employmenttype">
                  {{ error.employmenttype }}
                </p>
              </div>
              <div class="form-control1 label-clicked">
                <select name="course" id="course" v-model="userData.education">
                  <option value="graduate">College graduate & above</option>
                  <option value="12">12th Pass</option>
                  <option value="10">10th Pass & below</option>
                </select>
                <label for="mob-no" class="form-item-label">Education</label>
                <p class="error-text" v-if="error.education">
                  {{ error.education }}
                </p>
              </div>

              <div class="radio-question">
                <!--apply active class here for active state img-->
                <h4>Do you Smoke or Chew Tobacco?</h4>
                <div class="d-flex">
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="smoke"
                      id="tool-4"
                      v-model="userData.smoke"
                      v-bind:value="'Yes'"
                    />
                    <label class="for-checkbox-tools" for="tool-4">
                      <i><img src="~@/assets/images/smoking.svg" alt="" /></i>
                      <!-- <i class="inactive">
                        <img src="~@/assets/images/smoking-inactive.png" alt=""/>
                      </i> -->
                      Yes
                    </label>
                  </div>
                  <div class="">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="smoke"
                      id="tool-5"
                      v-model="userData.smoke"
                      v-bind:value="'No'"
                    />
                    <label class="for-checkbox-tools" for="tool-5">
                      <i>
                        <img src="~@/assets/images/non-smoking.svg" alt="" />
                      </i>
                      <!-- <i class="inactive">
                        <img src="~@/assets/images/no-smoking.png" alt=""/>
                      </i> -->
                      No
                    </label>
                  </div>
                </div>
                <p class="error-text" v-if="error.smoke">
                  {{ error.smoke }}
                </p>
              </div>
            </div>
          </div>
          <div class="text-success text-center" v-if="successMsg">
            {{ successMsg }}
          </div>
          <div class="modal-footer">
            <button type="button" v-on:click="updateProfile" class="save-btn">
              Save Profile
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    name: "Profile",
    emits: ["myVarChanged", "myVarChanged2"],
    /* props: {
        msg: String,
        userProfileData3
    }, */
    props: [
        'userProfileData3'
    ],
    watch: { 
      userProfileData3: function(newVal, oldVal) { // watch it
        console.log('Prop changed3: ', newVal, ' | was3: ', oldVal)
        this.user2Data = newVal;
      }
    },
    mounted() {
        if (sessionStorage.getItem("user_profile")) {
            this.userData = JSON.parse(sessionStorage.getItem("user_profile"));
        }
        if (sessionStorage.getItem("user2_profile")) {
            this.user2Data = JSON.parse(sessionStorage.getItem("user2_profile"));
        }

        this.getInLakhs();
    },
    data() {
        return {
            // Profile Details
            successMsg: "",
            successMsg2: "",
            error: [],
            error2: [],
            userData: {
                gender: null,
                fullname: null,
                dob: null,
                age: null,
                mobile: null,
                zipcode: null,
                annualincome: null,
                inlakhs: null,
                employmenttype: null,
                education: null,
                smoke: null,
                whatsapp: false,
            },
            user2Data: {
                fullname: null,
                gender: null,
                dob: null,
                age: null,
                employmenttype: null,
                smoke: null
            },
        }
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            console.log(charCode);
            //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode == 46) {
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        lettersSpaceOnly(evt) {
            evt = (evt) ? evt : event;
            var charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode : ((evt.which) ? evt.which : 0));
            if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
                evt.preventDefault();
            }
            return true;
        },
        lettersOnly(evt) {
          evt = (evt) ? evt : event;
          var charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode : ((evt.which) ? evt.which : 0));
          if (charCode > 31 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
            evt.preventDefault();
          }
          return true;
        },
        updateProfile() {
            self = this;
            
            console.log('userdata: ', this.userData);
            if (
                this.userData.gender &&
                this.userData.fullname.trim().length > 0 &&
                this.userData.dob &&
                this.userData.zipcode &&
                this.userData.annualincome &&
                this.userData.employmenttype &&
                this.userData.education &&
                this.userData.smoke
            ) {
                if(this.getAge(this.userData.dob) > 0) {
                  this.error = [];
                  if(this.userData.mobile.toString().length == '10' && this.checkSameString(this.userData.mobile.toString()) == false) {
                      this.error = [];
                      if(this.userData.zipcode.toString().length == '6' && this.checkSameString(this.userData.zipcode.toString()) == false) {
                          this.error = [];
                          if(this.userData.annualincome > 0 && this.userData.annualincome <= 50000000) {
                              this.error = [];
                              this.userData.age = this.getAge(this.userData.dob);
                              localStorage.setItem("userProfile", JSON.stringify(this.userData));
                              let filterPageValues = JSON.parse(sessionStorage.getItem('filterPageValues'))
                              if(JSON.parse(sessionStorage.getItem('user_profile')))
                              {
                                let sessionUserData=JSON.parse(sessionStorage.getItem('user_profile'))
                                if(this.userData.annualincome!=sessionUserData.annualincome)
                                {
                                  filterPageValues.quoteAmount=null
                                }
                                sessionStorage.setItem('filterPageValues',JSON.stringify(filterPageValues))
                              }
                              
                              sessionStorage.setItem("user_profile", JSON.stringify(this.userData));
                              this.successMsg = "Profile Updated Successfully.";
                              this.$emit('myVarChanged', this.userData);
                              // $("#editprofile").modal("hide");
                              // this.$router.go('/policy-quote');
                              setTimeout(function () {
                                $("#editprofile").modal("hide");
                                console.log('inside time out');
                                self.successMsg = "";
                                let cr = JSON.parse(localStorage.getItem("currentRoute"));
                                if(cr == 'policy-quote') {
                                  self.$router.go();
                                } else {
                                  self.$router.push('/policy-quote');
                                }
                              }, 1000);
                          } else { 
                            this.error["annualincome"] = "Annual Income Must be Upto 5 Cr.";
                          }
                          
                      } else {
                          this.error["zipcode"] = "Please Enter Valid Pincode";
                      }
                  } else {
                      this.error["mobile"] = "Please Enter Valid Mobile Number";
                  }
                } else {
                    this.error["dob"] = "Please Enter Valid Date of Birth";
                }
             
            } else {
                console.log("else block executed");
                this.error = [];
                if (!this.userData.gender) {
                    this.error["gender"] = "Please select gender first";
                }
                if (!this.userData.fullname.trim().length) {
                    this.error["fullname"] = "Please enter full name first";
                }
                if (!this.userData.dob) {
                    this.error["dob"] = "Please enter date of birth";
                }
                if (!this.userData.mobile) {
                    this.error["mobile"] = "Please enter mobile";
                }
                if (!this.userData.zipcode) {
                this.error["zipcode"] = "Please enter zipcode";
                }
                if (!this.userData.annualincome) {
                    this.error["annualincome"] = "Please enter annual income";
                }
                if (!this.userData.employmenttype) {
                    this.error["employmenttype"] = "Please select employment type";
                }
                if (!this.userData.education) {
                this.error["education"] = "Please select education";
                }
                if (!this.userData.smoke) {
                    this.error["smoke"] = "Please select option first";
                }
                console.log(this.error);
            }
        },
        updateProfile2() {
            self = this;
            // removing existing proposal session
            localStorage.removeItem("filledData");
            localStorage.removeItem("filledDataTab");
            sessionStorage.removeItem("filledData");
            sessionStorage.removeItem("filledDataTab");
            if (
                this.user2Data.fullname.trim().length > 0 &&
                this.user2Data.gender &&
                this.user2Data.dob &&
                this.user2Data.employmenttype &&
                this.userData.smoke
            ) {
                if(this.getAge(this.user2Data.dob) > 0) {
                    console.log("inside success block 2");
                    this.error2 = [];
                    this.user2Data.age = this.getAge(this.user2Data.dob);
                    localStorage.setItem("user2Profile", JSON.stringify(this.user2Data));
                    sessionStorage.setItem("user2_profile", JSON.stringify(this.user2Data));
                    this.successMsg2 = "Second Life Profile Updated Successfully.";
                    console.log('user 2: ', this.user2Data);
                    this.$emit('myVarChanged2', this.user2Data);
                    //$("#editsecondprofile").modal("hide");
                    //this.$router.go('/policy-quote');
                    setTimeout(function () {
                      $("#editsecondprofile").modal("hide");
                      self.successMsg2 = "";
                      //self.$router.push('/policy-quote');
                      self.$router.go('/policy-quote');
                    }, 1000);
                } else {
                    this.error2["dob"] = "Please enter valid date of birth";
                }
                
            } else {
                console.log("else 2 block executed");
                this.error2 = [];
                if (!this.user2Data.fullname.trim().length) {
                    this.error2["fullname"] = "Please enter full name";
                }
                if (!this.user2Data.gender) {
                    this.error2["gender"] = "Please select gender first";
                }
                if (!this.user2Data.dob) {
                    this.error2["dob"] = "Please enter date of birth";
                }
                if (!this.user2Data.employmenttype) {
                    this.error2["employmenttype"] = "Please select employment type";
                }
                if (!this.user2Data.smoke) {
                    this.error2["smoke"] = "Please select option first";
                }
                console.log(this.error);
            }
        },
        // getInLakhs() {
        //     this.userData.inlakhs = this.userData.annualincome / 100000;
        // },
        getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if (age < 99) {
                return age;
            }
        },
        calAge() {
            this.userData.age = this.getAge(this.userData.dob);
            this.error["dob"] = null;
        },
        calAge2() {
            this.user2Data.age = this.getAge(this.user2Data.dob);
            this.error2["dob"] = null;
        },
        getInLakhs() {
          let anuInc =  this.userData.annualincome / 100000;
          if(anuInc < 100) {
            this.userData.inlakhs = (this.userData.annualincome / 100000) + ' Lacs';
          } else {
            this.userData.inlakhs = (this.userData.annualincome / 10000000) + ' Cr.';
          }
        },
        checkSameString(data) {
          //console.log('check same string called');
          let firstChar = data[0];
          let len = data.length;
          let flag = true;
          for(let i = 1; i < len; i++) {
            if(firstChar != data[i]) {
              flag = false;
              break;
            }
          }
          return flag;
        },


    }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/assets/scss/components/profile.scss';
</style>
