<template>
    <div class="loading_block_outer" v-if="pageloading">
        <Loading3 msg='Please wait while we retrieve pending documents to upload.'/>
        <div class="snippet" data-title=".dot-spin">
            <div class="stage">
                <div class="dot-spin"></div>
            </div>
        </div>
        </div>
    <div v-else>
        <div class="policyname d-flex justify-content-around m-2" >
        <div class="policyname-text d-flex align-items-center">
          
          <router-link to="/dashboard" class="back-arrow">
            <img src="~@/assets/images/left-arrow-grey.png" alt="">
          </router-link>
          <span class="mr-4">
           <img v-if="this.insuranceCompanyCode" 
           
              :src="
                getInsurerImgs(
                    this.insuranceCompanyCode
                )
              "
              alt=""
            />
          </span>
          <span class="d-inline-block align-middle brand-text">
              {{ this.productName }}
            </span>
        </div>
        <div>
            <div v-if="this.applicationNo != '' && this.applicationNo != null " class="appno">Insurer Application Number : {{this.applicationNo}}</div>
            <div v-if="this.uiQuoteId != '' && this.uiQuoteId != null " class="propno">Reference Number : {{this.uiQuoteId}}</div>
        </div>
      </div>
      
      


    <div class="main-bg" ref="formContainer">
        <!-- <b-overlay :show="show" rounded="sm"> -->
            <div class="container" >
                <!-- <div class="back-link">
                    <router-link to="">
                        <img src="~@/assets/images/back-arrow.png" alt="" />Back To Policy Review
                    </router-link>
                </div> -->
                <div class="doc-upld-section">
                     <div class="d-flex justify-content-between doc-tab">
                    <button class="doc" :style="this.switchTabIndex==1?activeTab():null" @click="changeTab(1)">Document Upload</button>
                    <button class="doc" :style="this.switchTabIndex==2?activeTab():null" @click="changeTab(2)">Pending Operational Requirements</button>
                    <button v-if="this.b2b" class="doc" :style="this.switchTabIndex==3?activeTab():null" @click="changeTab(3)">System Generated Documents</button>
                </div>
                    
        <div v-if="this.switchTabIndex==1"> 
                    <div v-if="checkRequirements()">
                        <p>No documents are pending for submission.</p>
                    </div>
                    <div v-else>
                    <h4> <text class="mandatory-mark">*</text> Uploading document is necessary, however you can upload now or later in your dashboard</h4>
                    <!-- <h4>Upload Documents</h4> -->
                    </div>
                    <!-- <p>To confirm you are you, please upload digital photos of the following documents</p> -->
                    <ul>
                        <template v-for="(item, ind) in requirementJSON" :key="ind">
                            <li class="active" v-if="checkStatus(item)">
                               
                                <div class="d-flex justify-content-center align-items-center">
                                <div class="doc-width">
                                     <div class="d-flex justify-content-between align-items-center">
                                    <div class="doc-name d-flex justify-content-between align-items-center">
                                        <h4> {{ item.docCategoryDescription }} </h4> 
                                        <text class="mandatory-mark">*</text>
                                        <!-- <img src="~@/assets/images/info-button.png" alt="" class="ml-1"> -->
                                    </div>
                                    <!-- <div class="doc-file dashed-border">
                                        <select name="" id="">
                                            <option value="">option 2</option>
                                            <option value="">option 3</option>
                                        </select>
                                    </div> -->
                                </div>
                                <div class="d-flex justify-content-between align-items-center mt-3 bordered-section">
                                    <div class="w-30 file-icon-sec">
                                        <img src="~@/assets/images/businessman.png" alt="">
                                    </div>
                                    <div class="doc-upload d-flex w-70">
                                        <!-- <button class="flex-fill">
                                            <img src="~@/assets/images/camera.png" alt="">Camera
                                        </button> -->
                                        <div class="doc-upload-select"> 
                                            <select :name="item.SBDocCategory" :id="item.SBDocCategory"  v-model="docIds[item.SBDocCategory]" @change="resetErrorMsg($event, item.SBDocCategory)">
                                                <option value="">Select</option>
                                                <option v-for="(item2, ind2) in item.allowedSBDocId" :value="item2.SBDocId" :key="ind2">{{ item2.docIdDescription }} </option>
                                            </select>
                                        </div> <!--doc-upload-select-->
                                        <div class="upload-btn">
                                            <div class="upload-input">
                                                <input class="custom-file-input additional" type="file" title="Please select document types from drop down and then click upload" :id="'file_' + item.SBDocCategory" :disabled="checkError(item.SBDocCategory)" @change="previewFiles($event, item.SBDocCategory,ind)">
                                            </div>
                                            <img src="~@/assets/images/upload.png" alt="" />
                                        </div>
                                        <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" :data-target="'#imageModal_'+ item.SBDocCategory">
                                            <img src="~@/assets/images/eye.png" alt="" />
                                        </button>
                                    </div>
                                    
                                </div>
                                <div class="d-flex justify-content-between">
                                    <label>File size must be between:{{this.minFileSize}}-{{this.maxFileSize}}&nbsp;{{this.fileSizeUnit}}</label>
                                    <label>Supported File Types:{{this.supportedFileType}}</label>
                                </div>
                                
                                <div v-if="loading== true">
                                    <div class="snippet" data-title=".dot-spin">
                                      <div class="stage">
                                        <div class="dot-spin"></div>
                                       </div>
                                    </div>
                                    </div>
                                    <div v-if="loading==false">

                                      <div v-if="item.imgerror">
                                           <label v-if="arraySize(item.errormsg)"  class="imgerror">{{item.errormsg}}</label><br/>
                                      </div>
                                      <div v-if="item.imgerror">
                                           <label v-if="!arraySize(item.errormsg)"  class="imgerror">{{item.imgerror}}</label><br/>
                                      </div>

                                     <div v-if="!item.imgerror && this.finalStatus=='Success'" >
                                        <label v-if="arraySize(item.errormsg) " class="imgerror">{{item.errormsg}}</label>
                                        <label v-else-if="!arraySize(item.errormsg)"> <i v-if="item.fileName"><img src="~@/assets/images/uploaded.png" style="width:20px; height:20px" />{{item.fileName}}<br/>File Uploaded Successfully</i></label>
                                     </div>
                                     <div v-if="!item.imgerror && this.finalStatus=='Pending'" >
                                        <label v-if="!arraySize(item.errormsg)"> <i v-if="item.fileName"><img src="~@/assets/images/failed.png" style="width:20px; height:20px" />{{item.fileName}}<br/>Failed to Upload Document</i></label>
                                     </div>
                             

                                </div>
                                </div>
                                </div>
                                
                                <p class="error_msg" :id="'error_'+item.SBDocCategory"></p>
                            </li>
                            <!-- Modal -->
                            <div class="modal fade" :id="'imageModal_' + item.SBDocCategory" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">{{ item.docCategoryDescription }}</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <!-- <img class="image1" :id="'image1_' + item.SBDocCategory" :src="imagesObj[item.SBDocCategory]"> -->
                                            <embed
                                                class="doc_upload_block image1"
                                                v-if="imagesObj[item.SBDocCategory]"
                                                type="video/webm"
                                                :src="imagesObj[item.SBDocCategory]"
                                            />
                                            <div v-else  class="error_msg text-center doc_upload_block2">No Document Selected</div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ./End Modal -->
                        </template>
                    </ul>
                   
                
               
       <h4>History of Documents Upload</h4>
    <table  class="table dashbrd-table">
        <thead>
          <tr>
            <th></th>
            <th 
               style="cursor: pointer">
              SI. No.
            </th>
            <th style="cursor: pointer" >
              Requirement Type
            </th>
            <!-- <th style="cursor: pointer">
              Requirement Category
            </th>  -->
            <th style="cursor: pointer">
              Date Of Upload
            </th>
            <th style="cursor: pointer">
               Status
                    </th>
            <th style="cursor: pointer">
              Your Uploaded Document
            </th>
            <th  style="cursor: pointer">
              View
            </th>
          </tr>
        </thead>
        <tbody>
             <template v-for="(user,index) in this.docUploadHistory" :key="index">
            <tr
              
              class="accordion-toggle content-row-inner"
            >
            <td></td>
              <td>{{ index+1}}</td>
              <td style="">Upload</td>
              <!-- <td style="color:orange;">{{ user.requirementCat}}</td> -->
              <td style="">{{this.changeDateFormat(user.uploadedDate)}}</td>
              <td style="" >{{user.status}}</td>
               <td style="" >{{user.fileName}}</td>   
              <td><button type="button" class="btn btn-primary eyeicon-btn" @click="getUploadedFileUrl(user.filePath)">
                                            <img src="~@/assets/images/eye.png" alt="" />
                                        </button></td>
                                        <!-- <td><a href="https://insurance-pdf-dev.s3.amazonaws.com/b212cef4-7f94-4d7f-8596-56b6742720dc/uploaded/identityProof.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAZOA7B67YIPHILMCS%2F20230419%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230419T095015Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHoaCmFwLXNvdXRoLTEiRzBFAiA%2F88O81zxe7AuiZXPQzUxtjRuMUE%2FZVdclay8Xar6t1wIhALYugtkfftF0nHsrEDl2NFk%2BjfUWFmKRmbzEnoFUBz58KvcCCHMQARoMNjQ4NjA1MjAyNDE2IgyeT0wUDatLt%2FmzNpEq1AKCSa%2FOHEYE6CtZ862tbT2UIB14pa6etCRuqNVg%2BtwSuJEY06FNv9AP9t8ttNiUSUZhJanhAXb47aLFCcqrc0NAZP%2B%2BcaMRcv8JLylGMzLhlKu931Xo2UsRbgpsaBdsniddhYed%2Fgs%2FkIDF2sU6l1%2BsRZRoU7gmbYP0IP%2B26PtA%2Bb0eZz9kESiYqxYFhoiRI%2F6MSr5CIyJrP9Wy1YKE1JA11GwPpx6UK53Wb2TQDvkk7%2FjbE%2B2ZuqXcta%2FoYg6422miTQwExSD3Xr4XHT9WDAM8%2F1OOEGtPsUfi7GFYzOhByCkByKrxkE4cXCabOzeIjl50rM5ad2xANdoN0EaItgAuGinKivK7Mz78kM%2FtNYMaAD0SNc2OuXIahXIPqaTMwfyEMSPgnLeKYrkFnqfCF2MgQdzWq7TZlNffT6ZcfYt%2Bx7wUczWi2sKux2XKtC8VBo3z6GBfMJ7s%2FqEGOp4BY1w6roaY%2FYv4%2FwoCsKD4lKGPyIhAu%2F47n8YhrF854MKPYBJuVvXzaKgjsLzePDXmigAU2ZLX5JGkX1mOOcR9BQrrTNRjDBOAXtNPZG0kY63rnETKATwrDwBVdGu%2B3%2B67wNvFUqT%2By2QWA5LngTdvmF2Sy7FoYGio8v7Endd1Xg%2BClAk4XjbaFgcq8Ku51IJKo4VY2PGfvKAy9LgxoF0%3D&X-Amz-Signature=43fe8482fa8a05dd4749e6dd9c343d7aef1c171fc252827facd6db359eb3af14" target="_blank">Hi</a></td> -->
            </tr>
            
          </template>
        </tbody>
      </table>
       <div class="button wrap d-flex justify-content-center" >
                        <router-link to="/dashboard">
                            <button  type="submit" class="next-btn mb-4" >Proceed 
                            <i>
                                <img src="~@/assets/images/next-arrow.png" alt="">
                            </i>
                            </button>
                        </router-link>
                    </div>
</div>
<br>
<div v-if="this.switchTabIndex==2">
 <table class="table dashbrd-table">
        <thead>
          <tr>
            <th></th>
            <th 
               style="cursor: pointer">
              SI. No.
            </th>
            <th style="cursor: pointer" >
              Status
            </th>
            <th style="cursor: pointer">
              Requirement Category
            </th> 
            <th style="cursor: pointer">
              Doc Category
            </th>
            <th style="cursor: pointer">
               Description
                    </th>
            <th style="cursor: pointer">
              Requirement Date
            </th>
          </tr>
        </thead>
        <tbody>
             <template v-for="(user) in this.PendingOperationalJSON" :key="user.Status">
            <tr
              
              class="accordion-toggle content-row-inner"
            >
            <td></td>
              <td>{{ user.SINo}}</td>
              <td style="color:orange;">{{ user.Status}}</td>
              <td style="color:orange;">{{ user.requirementCat}}</td>
              <td style="color:orange;">{{user.DocCategory}}</td>
              <td style="width:20%;">{{user.Description}}</td>
              <td>{{user.RequirementDate}}</td>
            </tr>
            
          </template>
        </tbody>
      </table>

 <br>
 <h4>History of Documents Upload</h4>
 <table class="table dashbrd-table">
        <thead>
          <tr>
            <th></th>
            
            <th 
               style="cursor: pointer">
              SI. No.
            </th>
            <th style="cursor: pointer" >
              Requirement Type
            </th>
            <th style="cursor: pointer">
              Requirement Category
            </th> 
            
            <th style="cursor: pointer">
              Date Of Requirement Closed
            </th>
            <th style="cursor: pointer">
               Your Uploaded Document
                    </th>
    
          </tr>
        </thead>
        <tbody>
             <template v-for="(user) in this. UploadedDocJSON" :key="user.requirementType">
            <tr
              
              class="accordion-toggle content-row-inner"
            >
            <td></td>
              <td>{{ user.SINo}}</td>
              <td style="color:orange;">{{ user.requirementType}}</td>
              <td style="color:orange;">{{ user.requirementCat}}</td>
              <td style="color:orange;">{{user.DateOfRequirementClosed}}</td>
              <td style="color:orange;">{{user.YourUploadedDoc}}</td>
              </tr>
          </template>
        </tbody>
      </table>
</div>
 <br>
 <div v-if="this.switchTabIndex==3">
  <table  class="table dashbrd-table">
        <thead>
          <tr>
            <th></th>
            
            <th 
               style="cursor: pointer">
              SI. No.
            </th>
            <th style="cursor: pointer" >
              Document Name
            </th>
            <th style="cursor: pointer">
              Date Of Generation
            </th> 
            
            <th style="cursor: pointer">
              View
            </th>
            <th style="cursor: pointer">
               Download
                    </th>
    
          </tr>
        </thead>
        <tbody>
             <template v-for="(user,ind) in this.generatedDoc" :key="ind">
            <tr
              
              class="accordion-toggle content-row-inner"
            >
            <td></td>
              <td>{{ ind+1}}</td>
              <td style="">{{ user.fileName}}</td>
              <td>{{ this.changeDateFormat(user.uploadedDate)}}</td>
              <td><button type="button" class="btn btn-primary eyeicon-btn" @click="getUploadedFileUrl(user.filePath)">
                    <img src="~@/assets/images/eye.png" alt="" />
                  </button></td>
              <td><button type="button" class="btn btn-primary"  title="Download Policy Document" @click="getUploadedFileUrl(user.filePath)">
                <img src="~@/assets/images/downloadPolicy.png" class=" d-flex justify-content-center align-items-center" alt="" title="Download Policy Document" /> </button></td>
             
              </tr>           
          </template>
        </tbody>
      </table>
     
<div class="bottum-btn d-flex justify-content-center">
 

    <div
        class="
          footer-download-btn
          d-flex
          justify-content-center
          align-items-center bottum-btn
        "
      >
         <button type="button">For future Use</button>
  <button type="button">BI Download</button>
    <button v-if="this.proposalStatus!='Payment Success'" type="button">Payment Receipt</button>
      <button v-if="this.proposalStatus!='Requirements Pending' && this.proposalStatus!='Payment Success'" type="button">Policy</button>
       
      </div>
      </div>
 </div>
</div>
</div>
</div>    
</div>
    
</template>


<script>
import $ from "jquery";
import Uploading from "@/components/uploading.vue";
// Import the method.
import { useLoading } from 'vue3-loading-overlay';
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Loading3 from "@/components/loading3.vue";
import { getDistributer } from '@/mixins/common';
import {getInsuranceImages} from '@/mixins/common';
export default {
    name:' uploaddoc',
    components: {
        Uploading,
        Loading3
    },
    mixins: [getDistributer,getInsuranceImages],
    mounted() {
        if(sessionStorage.getItem("rm_data"))
        {
        this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
        console.log(this.rmData,"rmData")
        if( this.rmData['RoleId']=="1")
            this.b2b=false
        else if(this.rmData['RoleId']==" " || this.rmData['RoleId']=="2")
            this.b2b=true    
        }
        console.log(this.b2b,"fsdhfjs") 
        this.loading = false;
        this.imagesObj = {};
        this.distributorID = this.$route.query.distributorID;
        this.insuranceCompanyCode = this.$route.query.insuranceCompanyCode;
        this.proposalStatus=this.$route.query.status;
        this.proposalUiId=this.$route.query.proposalUiId
        this.DOB = this.$route.query.dob;
        this.mobileNumber = this.$route.query.mobileNumber;
        let spdetail_flag= process.env.VUE_APP_SPDETAIL_ONESB;
        if(spdetail_flag == 1)
        {
                if (sessionStorage.getItem("rm_data")){
                    this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
                    if( this.rmData['RoleId']!=="1"){
                        this.getSPDetailsOneSB(this.insuranceCompanyCode);
                    }
                    
                }   
        }
        this.applicationNo = this.$route.query.applicationNo;
        this.productCode=this.$route.query.productCode;
        console.log("this.productCode",this.productCode);
        
        
        this.getUploadedFile()
        if (sessionStorage.getItem("uiQuoteId")) {
             this.uiQuoteId = sessionStorage.getItem("uiQuoteId");
             console.log("uiQuoteId",this.uiQuoteId)
    }
    if (sessionStorage.getItem("proposal_ui_id")) {
             this.proposalUiId = JSON.parse(sessionStorage.getItem("proposal_ui_id"));
             console.log("proposalUiId",this.proposalUiId)
    }

        this.getProductName();
        if(this.distributorID == "undefined")
        {
            this.distributorID = getDistributer()["distributerID"];
        }

        console.log('this.distributorID: ', this.distributorID, 'this.insuranceCompanyCode: ', this.insuranceCompanyCode, 'this.applicationNo: ', this.applicationNo)

        this.testRequirementJSON = [
            {
                "status":"Pending",
                "manufacturerStatus":"",
                "SBDocCategory":"addrProof",
                "docCategoryDescription":"Address Proof",
                "allowedSBDocId":[
                {
                    "SBDocId":"addrProofPartnrFirm",
                    "docIdDescription":"Address proof of the Partnership Firm"
                },
                {
                    "SBDocId":"tcsBonafideLetter",
                    "docIdDescription":"TCS Bonafide Letter (Proof of Address)"
                }
                ],
                "description":"",
                "requestDate":"",
                "examDate":"",
                "receivedDate":"",
                "clientId":"",
                "firstName":"",
                "lastName":"",
                "manuRequirementNo":"",
                "varFields":[
                    {
                        "fieldName":"",
                        "fieldValue":""
                    }
                ]
            },
            {
                "status":"Pending",
                "manufacturerStatus":"",
                "SBDocCategory":"ageProof",
                "docCategoryDescription":"Age Proof",
                "allowedSBDocId":[
                {
                    "SBDocId":"aadhar",
                    "docIdDescription":"Aadhar ID Card - Non Standard"
                },
                {
                    "SBDocId":"baptismCert",
                    "docIdDescription":"Baptism Certificate"
                },
                {
                    "SBDocId":"defIdCard",
                    "docIdDescription":"Defense ID Card"
                },
                {
                    "SBDocId":"domicileCert",
                    "docIdDescription":"Domicile Certificate"
                }
                ],
                "description":"",
                "requestDate":"",
                "examDate":"",
                "receivedDate":"",
                "clientId":"",
                "firstName":"",
                "lastName":"",
                "manuRequirementNo":"",
                "varFields":[
                    {
                        "fieldName":"",
                        "fieldValue":""
                    }
                ]
            }
        ];
        this.user = JSON.parse(sessionStorage.getItem("user_profile"))
        // this.requirementJSON = this.testRequirementJSON;

        this.getDocRequirement(this.distributorID, this.insuranceCompanyCode, this.applicationNo,this.productCode);
    },
    props: {
        msg: String,
    },
    data() {
        return {
            b2b:true,
            user:{},
            loading: true,
            pageloading: false,
            requirementJSON: "",
            testRequirementJSON: "",
            proposalStatus:"",
            docUploadHistory:[],
            generatedDoc:[],
            DocUploadJSON:[{
            "SINo" : "1",
            "requirementType": "Upload",
            "requirementCat": "Document",
            "DateOfUpload": "21-1-2023",
           "Status": "Submitted-Pending Review",
           "YourUploadedDoc": "Drop",
           "view":"eye"
            },
    
{            
             "SINo" : "2",
            "requirementType": "Upload",
            "requirementCat": "Document",
            "DateOfUpload": "21-1-2023",
           "Status": "WaivedOff",
           "YourUploadedDoc": "Drop",
           "view":"eye"
            },
{

             "SINo" : "3",
            "requirementType": "Upload",
            "requirementCat": "Document",
            "DateOfUpload": "21-1-2023",
           "Status": "Accepted",
           "YourUploadedDoc": "Drop",
           "view":"eye"
            },

            {            
             "SINo" : "4",
            "requirementType": "Upload",
            "requirementCat": "Document",
            "DateOfUpload": "21-1-2023",
           "Status": "Accepted",
           "YourUploadedDoc": "Drop",
           "view":"eye"
            },
            {            
             "SINo" : "5",
            "requirementType": "Upload",
            "requirementCat": "Document",
            "DateOfUpload": "21-1-2023",
           "Status": "Submitted-Pending Review",
           "YourUploadedDoc": "Drop",
           "view":"eye"
            },
              {            
             "SINo" : "6",
            "requirementType": "Upload",
            "requirementCat": "Document",
            "DateOfUpload": "21-1-2023",
           "Status": "Reordered",
           "YourUploadedDoc": "Drop",
           "view":"eye"
            },
              {            
             "SINo" : "7",
            "requirementType": "Upload",
            "requirementCat": "Document",
            "DateOfUpload": "21-1-2023",
           "Status": "Submitted-Pending Review",
           "YourUploadedDoc": "Drop",
           "view":"eye"
            },

           ],
            PendingOperationalJSON:[{
            "SINo" : "1",
            "Status": "Pending",
            "requirementCat": "Medical",
            "DocCategory": "Operational",
           "Description": "Description by the Manufacturer",
           "RequirementDate": "01/03/2023",
           },
            {
             "SINo" : "2",
            "Status": "Pending",
            "requirementCat": "Non-Doc",
            "DocCategory": "Operational",
           "Description": "Description by the Manufacturer",
           "RequirementDate": "01/03/2023",

            },
             {
             "SINo" : "3",
            "Status": "Cancelled",
            "requirementCat": "Non-Doc",
            "DocCategory": "Operational",
           "Description": "Description by the Manufacturer",
           "RequirementDate": "01/03/2023",

            },
            ],

            UploadedDocJSON:[{
                         
             "SINo" : "1",
            "requirementType": "Operational",
            "requirementCat": "Non-Doc",
            "DateOfRequirementClosed": "02/03/2023",
           "YourUploadedDoc": "Submitted-Pending Review",
           },

           {
                         
             "SINo" : "2",
            "requirementType": "Operational",
            "requirementCat": "Medical",
            "DateOfRequirementClosed": "02/03/2023",
           "YourUploadedDoc": "WaivedOff",
           },

           {
                         
             "SINo" : "3",
            "requirementType": "Operational",
            "requirementCat": "Non-Doc",
            "DateOfRequirementClosed": "02/03/2023",
           "YourUploadedDoc": "Accepted",
           },

             {
                         
             "SINo" : "4",
            "requirementType": "Operational",
            "requirementCat": "Medical",
            "DateOfRequirementClosed": "02/03/2023",
           "YourUploadedDoc": "Accepted",
           },

            {
                         
             "SINo" : "5",
            "requirementType": "Operational",
            "requirementCat": "Non-Doc",
            "DateOfRequirementClosed": "02/03/2023",
           "YourUploadedDoc": "Submitted-Pending Review",
           },

           {
                         
             "SINo" : "6",
            "requirementType": "Operational",
            "requirementCat": "Non-Doc",
            "DateOfRequirementClosed": "02/03/2023",
           "YourUploadedDoc": "Reordered",
           },
           
            {
                         
             "SINo" : "7",
            "requirementType": "Operational",
            "requirementCat": "Medical",
            "DateOfRequirementClosed": "02/03/2023",
           "YourUploadedDoc": "Submitted-Pending Review",
           },

],

           PendingRequirementJSON:[{
             "SINo" : "1",
            "DocumentName": "ACR",
            "DateOfGeneration": "21/01/2000",
            "EyeButton": "Eye",
            "DownloadButton":"Download"
           },
            {
           "SINo" : "2",
            "DocumentName": "Occuption Questionnaire",
            "DateOfGeneration": "21/01/2000",
            "EyeButton": "Eye",
            "DownloadButton":"Download"
           },

           {
           "SINo" : "3",
            "DocumentName": "NRI Questionnaire",
            "DateOfGeneration": "21/01/2000",
            "EyeButton": "Eye",
            "DownloadButton":"Download"
           },

           ],

            
            distributorID: this.distributorID,
            insuranceCompanyCode: "",
            applicationNo: "",
            productCode:"",
            productName:"",
            salesChannel: "Others",
            docIds: [],
            imagesObj: {},
            //loader: useLoading(),
            docCategoryDone:[],
            maxFileSize:0,
            minFileSize:0,
            fileSizeUnit:"",
            supportedFileType:"",
            fileName:"",
            imgerror:"",
            errormsg:[],
            finalStatus:"",
            finalCheck:[],
            proposalUiId:"",
            DOB:"",
            mobileNumber:"",
            switchTabIndex:1,
            spDetails: {},
            rmData: {},

        }
    },
    methods: {
        changeTab(index)
        {
            console.log("index",index)
            this.switchTabIndex=index
        },
        activeTab()
        {
            return "background-color:#fa9d17;"
        },
        // finalCheckStatus(){
        //     if(this.finalCheck.length == this.requirementLength){
        //             console.log("status Updated to Proposal Submitted")
        //             this.updateProposalStatus(9);  
        //         }
        //     else{    
        //         if( this.docCategoryDone.length + this.finalCheck.length == this.requirementLength){  
        //             console.log("status Updated to Proposal Submitted")
        //             this.updateProposalStatus(9);
        //         }
        //         else{
        //             this.updateProposalStatus(2);
        //             console.log("status Updated to Pending Requirements")
        //         } 
        //     }
        // },
    changeDateFormat(inp) {
      var str = "";
      let monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      var d;
      if ( inp!="" && typeof inp == typeof str) {
        inp= inp+'Z';
        d = new Date(Date.parse(inp));
        str =
          d.getDate().toString() +
          "-" +
          monthNames[d.getMonth()] +
          "-" +
          d.getFullYear().toString() +
          " " + 
          d.toLocaleTimeString('en-IT', { hour12: false , timeZone:'Asia/Kolkata'});
                }
      return str;
    },
        getUploadedFile()
        {
        let reqData = [this.proposalUiId];
        let headers ={};
        this.backendApi("getUploadedFile", reqData, headers, "get")
        .then((response) => {
            console.log("resp get uploaded doc",response)
            if(response && response.data.data && response.data.data.files && response.data.data.files.uploaded)
            {
                this.docUploadHistory=response.data.data.files.uploaded
                if(response.data.data.files.generated)
                {
                    this.generatedDoc=response.data.data.files.generated
                    for(let i=0;i<this.generatedDoc.length;i++)
                    {
                        let fileName=this.generatedDoc[i].fileName.split('.')
                        this.generatedDoc[i].fileName=fileName[0]
                    }
                console.log("this.generatedDoc",this.generatedDoc)
                }
            }
            
            
        })
        .catch((error) => {
            console.log('error msg block spdetailOneSB API', error);
            
        });
        },
        getUploadedFileUrl(path)
        {
            console.log("Path",path)
        let reqData = [path];
        let headers ={};
        this.backendApi("getUploadedFileUrl", reqData, headers, "get")
        .then((response) => {
            console.log("resp uploaded file url",response)
            if(response && response.data.data && response.data.data.fileUrl)
            {
                let URL= response.data.data.fileUrl
                window.open(URL)
            }
            
            
        })
        .catch((error) => {
            console.log('error msg block spdetailOneSB API', error);
            
        });
        },
        getSPDetailsOneSB(man_code ) {
        let reqData = [man_code];
        let headers ={};
        this.backendApi("getSPDetailsOneSB", reqData, headers, "get")
        .then((response) => {
            if(response == 'error') {
            self.apiError = true;
            } else if(response && response.data) {
            console.log('response sp details: ', response.data);
            if(response.data.status == "failed")
            {
                //redirection to error page and on go back button redirect to homepage
                let msg=['SP details are not available']
                this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote', msg: msg }});
            }
            this.spDetails = response.data.data;
            console.log("===============",this.spDetails.spCode);
            //   this.quotationSQcall();
            // sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
            // localStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
            // this.spdataFlagForSQ = true;
            // this.apiReqData.distributor.agentID = this.spDeatils.spCode;  
            // for(var attr in this.spdata.attributes)
            //     {
            //         try{
            //             let varfield = {};
            //             varfield["fieldName"] = attr;
            //             varfield["fieldValue"] =this.spdata.attributes[attr];
            //             this.apiReqData.distributor.varFields.push(varfield);
            //         }catch(err){
            //             console.log("varfield assignment failed",err);
            //         }            
            //     }
            }
        })
        .catch((error) => {
            console.log('error msg block spdetailOneSB API', error);
            let msg=['SP details are not available']
            this.$router.push({ path: '/networkalert', query: {backUrl:'dashboard', msg: msg }});
        });
        },
        checkRequirements(){
            if(this.requirementJSON.length == this.docCategoryDone.length)
            {
                return true;
            }
            else
            {
                return false;
            }
        },
        arraySize(value){

            if( value instanceof Array){
                return value.length != 0
            }
        },
        checkStatus(item)
        {
            if(item.SBDocCategory == "")
            {
                return false;
            }
            let status = this.docCategoryDone.includes(item.SBDocCategory);
            return !status; 
        },
        checkError(sbDocCat)
        {    
            if(!this.docIds[sbDocCat])
            {
                return true;
            }
            else
            {
                return false;
            }
        },
        updateProposalStatus(statusid)
        {
        let qData = {
          "proposal_ui_id": this.proposalUiId,
          "status_id": statusid
          };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
          };
        
        this.backendApi("ProposalStatus", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
              // let msg=['We are facing some issue while saving proposal status']
              // this.$router.push({ path: '/alert-diy', query: {msg: msg }});
            }
            
             else if(response && response.data) {
              console.log('proposal status updated: ', response.data)
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
            // let msg=['We are facing some issue from backend while saving proposal status']
            //   this.$router.push({ path: '/alert-diy', query: {msg: msg }});
          });
        },
        
        previewFiles(event, sbDocCat,index) {
            let file = event.target.files[0];
            this.imgerror="";
            this.errormsg=[];
                let minfs;
                let maxfs;
                if(this.fileSizeUnit=="KB"){
                    minfs=this.minFileSize*1024;
                    maxfs=this.maxFileSize*1024;
                    
                }
                
                if(this.fileSizeUnit=="MB"){
                    minfs=this.minFileSize*1024*1024;
                    maxfs=this.maxFileSize*1024*1024;
                  }
                if(file.size < minfs)  {
                    this.imgerror = `The file size is less than allowed file limit`;  
                }
                if(file.size > maxfs){
                    this.imgerror = `The file size is greater than allowed file limit`;  
                }
                
            console.log('get file called: ', event.target.files[0]);
            this.fileName =  event.target.files[0].name;
            for(let i=0;i<this.requirementJSON.length;i++)
            {
                if(index==i){
        
                       this.requirementJSON[i]["fileName"]=this.fileName
                       this.requirementJSON[i]["imgerror"]=this.imgerror
                       if(!this.docIds[sbDocCat]){
                           this.requirementJSON[i]["errormsg"].push("Please Select Doc Type First Then Select File");
                       }
                }
                
            }
            console.log("reqjson",this.requirementJSON)
            console.log('sbDocCat: ', sbDocCat);
            this.loading = false;

            console.log('docIds: ', this.docIds);
            if(!this.docIds[sbDocCat]){
                // $("#error_" + sbDocCat).text('Please Select Doc Type First Then Select File');
                // $('#file_' + sbDocCat).val('');
                // this.loading = true;
            } else {

                let fileType = file.type;
                console.log('file type: ', fileType);
                let extension = file.name.split('.').pop();
                console.log('extension: ', extension);

                const reader = new FileReader();
                let srcUrl = URL.createObjectURL(file);
                this.imagesObj[sbDocCat] = srcUrl;
                
                console.log('srcUrl: ', srcUrl);

                this.getBase64(file).then(
                    data => {   
                        this.uploadDoc(fileType, data, extension, sbDocCat, this.docIds[sbDocCat],index);
                    } 
                )

            }
            
            this.loading = true;

        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        uploadDoc(fileType, base64String, extension, sbDocCat, sbDocId,index) {

            let filledData;
            if (sessionStorage.getItem('filledData')) {
                filledData = JSON.parse(sessionStorage.getItem("filledData"));
            }
            let policynumber = "";
            try{
                policynumber = filledData.fieldGroups.traceInfo.fields.manuPolicyNo.input;
            }
            catch(err){
                console.log("policy number assignment failed", err);
            }
            if (sessionStorage.getItem('filledData')) {
                filledData = JSON.parse(sessionStorage.getItem("filledData"));
            }
            var base64result = base64String.split(',')[1];
            let qData = 
            {
                "data":
                {
                    "distributor": {
                        "distributorID": this.distributorID,
                        "agentID": this.spDetails.spCode,
                        "salesChannel": this.salesChannel,
                        "channelType": this.rmData.RoleId !== "1" ? "B2B" : "B2C",
                        "varFields": [{
                            "fieldName":"",
                            "fieldValue":""
                        }]
                    },
                    "insuranceCompanyCode": this.insuranceCompanyCode,
                    "applicationNo": this.applicationNo,
                    "policyNo": policynumber,
                    "quoteId": "",
                    "proposalId":this.proposalUiId,
                    "individualDetails": [
                        {
                            "memberType": "Life Assured",
                            "memberSequenceNumber": 1,
                            "relationWithFirstLifeAssured": "Self",
                            "fileDetails": [
                                {
                                    "SBDocCategory": sbDocCat,
                                    "SBDocId": sbDocId,
                                    //"SBDocCategory": sbDocCat,
                                    //"SBDocId": sbDocId,
                                    "documentId": "",
                                    "documentExpiryDate": "",
                                    "byteArray": base64result,
                                    "docFormat": extension,
                                    "manuRequirementNo": "",
                                    "varFields": [
                                        {
                                            "fieldName": "",
                                            "fieldValue": ""
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "varFields": [
                        {
                            "fieldName": "",
                            "fieldValue": ""
                        }
                    ]
                }
            };

            var  headers = {
                "quoteId":this.uiQuoteId,
                Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
                "Content-Type": "application/json",
            };
            
            //this.loading = true;
            this.show = true;
            // this.loader.show({
            //     canCancel: false,
            // });
            this.backendApi("uploadDoc", qData, headers)
                .then((response) => {
                    //console.log('save quote response: ', response);
                    this.loading = false;


                    //this.loader.hide();
                    if(response == 'error') {
                        self.apiError = true;
                    } else if(response.data.errors.length > 0) {
                        // let msg = '';
                        // response.data.errors.forEach((element, index) => {
                        // msg += element.errorMessage + '\n';
                        // });
                        let msg=[]

                        for(let i=0;i<response.data.errors.length;i++)
                        {
                            msg.push(response.data.errors[i]["errorMessage"])
                           
                        }

                        this.errormsg=msg;

                        this.requirementJSON[index]["errormsg"]=this.errormsg;
                        console.log("index",index);
                        console.log("errormsginupdoc",this.errormsg)
                        // this.loading = true;

                        console.log("errormsg",this.errormsg)
                       
                        // this.errormsg.push("Incorrect File Type");
                        // this.$router.push({ path: '/alert', query: {backUrl:'policy-quote', msg:msg  }});
                        // alert(msg);
                    } 
                    else if(response && response.data){
                        if(response.data.data.individualDetails[0]?.fileDetails[0]?.fileUploadStatus == 'Success') {
                           if(response.data.errors.length==0){
                            this.errormsg=[];
                            this.requirementJSON[index]["errormsg"]=this.errormsg;
                           }
                            // alert('File Uploaded Successfully');
                            if(this.imgerror==""){
                            this.uploadDocumentStatus(this.applicationNo,sbDocCat, "Success");
                            this.loading=true;
                            

                            }
                        }
                    
                    } 
                })
                .catch((error) => {
                    //this.loading = false;
                    //this.loader.hide()
                    console.log('error msg block doc uploading', error);
                });
        },
        resetErrorMsg(event, sbDocCat) {
            let val = event.target.value;
            console.log('event: ', event.target.value);
            console.log('sbDocCat: ', sbDocCat);

            if(val) {
                $("#error_"+sbDocCat).text('');
            }
        },
        getUploadRequiremnts(appnum,manufacturer,policyNumber){
            // console.log(requirement);
            let  headers = {"quoteId":this.uiQuoteId,};
            let qData = [appnum];

            this.backendApi("getUploadDocRequiremnts", qData, headers,"get")
                .then((response) => {
                    console.log(response);
                    this.docCategoryDone =[];
                    for(let i=0;i<response.data.length;i++)
                    {
                        this.docCategoryDone.push(response.data[i].category);
                    }
                    console.log(this.docCategoryDone);
                    for(let i=0;i<this.requirementJSON.length;i++)
                            { 
                                this.uploadRequiremnts(this.requirementJSON[i], 
                                appnum,
                                manufacturer,
                                policyNumber);
                            }
                    this.pageloading = false;
                    this.laoding= false;
                })
                .catch((error) => {
                    this.pageloading = false;
                    this.loading = false;
                    // this.show = false;
                    console.log('error msg block doc getUploadDocRequiremnts', error);
                });

        },
        uploadRequiremnts(requirement,appnum,manufacturer,policyno){
            console.log(requirement);
            this.minFileSize = requirement.minFileSize;
            this.maxFileSize = requirement.maxFileSize;
            this.fileSizeUnit = requirement.fileSizeUnit;
            this.supportedFileType = requirement.fileType;
            if(requirement.docCategoryDescription == "" ||requirement.SBDocCategory == "")
            {
                return;
            }
            let proposal_ui_id="";
            if (sessionStorage.getItem('proposal_ui_id')) {
                proposal_ui_id = JSON.parse(sessionStorage.getItem('proposal_ui_id'));
            }
            let  headers = {"quoteId":this.uiQuoteId,};
            let qData = {
                "proposal_ui_id":proposal_ui_id,
                "applicationNumber": appnum,
                "manufacturer":manufacturer,
                "insuranceCompanyCode":"",
                "productCode":"",
                "policyNumber":"",
                "insurance_company_status":"Pending",
                "manufacturerStatus":"Pending",
                "category":requirement.SBDocCategory,
                "name": requirement.firstName,
                "description": requirement.docCategoryDescription,
                "request_date":requirement.requestDate,
                "exam_date":requirement.examDate,
                "received_date": requirement.receivedDate,
                "clientId": requirement.clientId,
                "firstName":requirement.firstName,
                "lastName":requirement.lastName
                
            };

            this.backendApi("uploadDocRequiremnts", qData, headers)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    this.pageloading = false;
                    this.show = false;
                    console.log('error msg block doc requirement', error);
                });

        },
        uploadDocumentStatus(applicationNumber,category,status){
            
            let  headers = {"quoteId":this.uiQuoteId,};
            let qData = {
                "applicationNumber":applicationNumber,
                "category":category,
                "status":status};

            this.backendApi("uploadDocStatus", qData, headers)
                .then((response) => {
                    console.log(response);
                    this.finalStatus = response.data.insurance_company_status
                    if(this.finalStatus =='Success'){
                        this.finalCheck.push(this.finalStatus)
                    }
                    if(this.finalCheck.length == this.requirementLength){
                        console.log("status Updated to Proposal Submitted")
                        this.updateProposalStatus(9);  
                    }
                    else if( this.docCategoryDone.length + this.finalCheck.length == this.requirementLength){  
                        console.log("status Updated to Proposal Submitted")
                        this.updateProposalStatus(9);
                    }
                    this.loading= false; 
                })
                .catch((error) => {
                    this.show = false;
                    this.loading = false;
                    console.log('error msg block doc requirement', error);
                });

        },
        getDocRequirement() {
            this.pageloading = true;
            let  headers = {"quoteId":this.uiQuoteId,};
            let qData = 
            {
                "data":
                {
                    "distributor": {
                            "distributorID": this.distributorID,
                            "agentID": "",
                            "salesChannel": "",
                            "channelType": this.rmData.RoleId !=="1" ? "B2B" : "B2C",
                            "varFields": [{
                                "fieldName":"",
                                "fieldValue":""
                            }]
                        },
                    "insuranceCompanyCode": this.insuranceCompanyCode,
                    "applicationNo": this.applicationNo,
                    "memberDetails":{
                        "dateOfBirth":this.DOB,
                        "mobileNumber": parseInt(this.mobileNumber)
                    }
                }
            };
            //this.loading = true;
            this.show = true;
            this.backendApi("getDocRequirements", qData, headers)
                .then((response) => {
                    //console.log('save quote response: ', response);
                    // this.pageloading= false;
                    this.show = false;
                    if(response == 'error') {
                        console.log('error block for get requirement');
                    }
                    else if(response.data.errors.length>0)
                    {
                        let msg=[]
                        this.errorMsg="We are facing some issue while fetching documents requirement"
                        for(let i=0;i<response.data.errors.length;i++)
                        {
                            msg.push(response.data.errors[i]["errorMessage"])
                        }
                        this.$router.push({ path: '/networkalert', query: {backUrl:'dashboard',msgError:this.errorMsg,reqId:response.data.reqId, msg:msg  }});
                    }
                    else if(response && response.data) {
                        
                        if(response.data.data.manufacturer) {
                            this.requirementJSON = response.data.data.manufacturer.product[0].requirements;
                            this.getUploadRequiremnts(response.data.data.applicationNumber,
                            response.data.data.manufacturer.insuranceCompanyCode,
                            response.data.data.manufacturer.product[0].policyNo);
                       for(let i=0;i<this.requirementJSON.length;i++)
                       {
                        this.requirementJSON[i].fileName=""
                        this.requirementJSON[i].imgerror=""
                        this.requirementJSON[i].errormsg=[]


                       }

                            // for(let i=0;i<this.requirementJSON.length;i++)
                            // {
                            //     this.uploadRequiremnts(this.requirementJSON[i], 
                            //     response.data.data.applicationNumber,
                            //     response.data.data.manufacturer.insuranceCompanyCode,
                            //     response.data.data.manufacturer.product[0].policyNo);
                            // }
                            
                        }
                        console.log('requirement response: ', response.data);
                        this.requirementLength = response.data.data.manufacturer.product[0].requirements.length
                        console.log(" this.requirementLength", this.requirementLength)


                    
                    }
                    this.pageloading= false;
                })
                .catch((error) => {
                    this.pageloading = false;
                    this.show = false;
                    console.log('error msg block doc requirement', error);
                });
        },
        getInsurerImgs(value) {
                let img = getInsuranceImages();
                return require("../../assets/images/" + img[value]);
                },
        getProductName(){
            // this.pageloading = true;
            let  headers = {};
            let qData = [this.productCode,this.insuranceCompanyCode]
            console.log("this.productCode",this.productCode);
           
            this.show = true;
            this.backendApi("getProductName", qData, headers,"get")
                .then((response) => {
                    
                    // this.pageloading= false;
                    this.show = false;
                    console.log("getProductName",response)
                    if(response == 'error') 
                    {
                        console.log('error block for get productName');
                    }
                    else if(response && response.data.data.productName) 
                    {
                        // if(response.data.manufacturer) {
                            this.productName = response.data.data.productName;

                            console.log('productName:inside api', this.productName );
                           
                        // };
                    }
                })
                .catch((error) => {
                    this.pageloading = false;
                    this.show = false;
                    console.log('error msg block doc requirement', error);
                });
        }
    }
}
</script>


<style scoped lang="scss">
@import '~@/assets/scss/components/uploaddoc.scss';
.dot-spin {
  position: fixed;
  right: 50%;
  bottom: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}
@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}
</style>