<template>
    <footer>
        <ul>
            <li v-if="terms.terms!=''">
                <a target="_blank" :href="terms.terms">Terms &amp; Conditions</a>
            </li>
            <li v-if="terms.disclaimer!=''">
                <a target="_blank" :href="terms.disclaimer">Disclaimer</a>
            </li>
        </ul>
    </footer>
</template>

<script>
import { getTermsCondition } from '@/mixins/common';
export default {
  name: "footer",
  props: {
    msg: String
  },
  mixins:[getTermsCondition],
  mounted() {
    this.terms = getTermsCondition();
  },
  data() {
      return {
        terms: "",
      }
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/footer.scss';
</style>