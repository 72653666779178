<template>
    <div>
        <!-- <innerheader /> -->
        <div v-if="this.rmData['RoleId']=='1'">
        <innerheader />
      </div>
        <alertdiypage />
    </div>
</template>

<script>
// @ is an alias to /src
// import innerheader from "@/components/innerheader/innerheader.vue";
import innerheader from "@/components/innerheader/innerheader.vue";
import alertdiypage from "@/components/alert/alertdiypage.vue";

export default {
  name: 'alertDiy',
  components: {
     innerheader,
     alertdiypage
  },
  data()
  {
    return{
      rmData:"",
    }
  },
   mounted()
  {
     if(sessionStorage.getItem("rm_data"))
     {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
     }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/paymentsuccess.scss';
</style>